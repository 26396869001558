/* You can add global styles to this file, and also import other style files */
body {
  margin: 0;
  overflow-x: hidden;
}
canvas {
  display: none !important;
}
.card canvas {
  display: block !important;
}
@font-face {
  font-family: spot;
  src: url(assets/fonts/Spot-Normal.ttf);
}
@font-face {
  font-family: spotOutline;
  src: url(assets/fonts/Spot-Outline.ttf);
}
@font-face {
  font-family: montserratRegular;
  src: url(assets/fonts/Montserrat-Regular.ttf);
}
body::-webkit-scrollbar-track {
  border: none;
  margin-right: 6px;
  background-color: #652cb3;
}

body::-webkit-scrollbar {
  border-radius: 10px;
}

body::-webkit-scrollbar-thumb {
  border-radius: 10px;
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #737272;
  border: 1px solid #000;
}